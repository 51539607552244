// project imports
import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';

// third-party
import { createSlice } from '@reduxjs/toolkit';

// ----------------------------------------------------------------------

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  error: null,
  esgProducts: [],
  esgProduct: null,
  relatedEsgProducts: [],
  reviews: [],
  addresses: [],
  isEsgProductBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isCustomerOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'esgProduct',
  initialState,
  reducers: {
    // review esgProduct popup
    reviewEsgProductPopup(state, action) {
      state.isOpen = action.payload.isOpen;
    },

    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // is customer open
    customerPopup(state, action) {
      state.isCustomerOpen = action.payload.isCustomerOpen;
    },

    // GET PRODUCTS
    // getEsgProductsSuccess(state, action) {
    //   state.esgProduct = action.payload;
    // },

    // FILTER PRODUCTS
    filterEsgProductsSuccess(state, action) {
      state.esgProduct = action.payload;
    },

    // GET PRODUCT
    getLists(state, action) {
      state.lists = action.payload;
    },

    getMonthlyList(state, action) {
      state.lists = action.payload;
    },

    // GET RELATED PRODUCTS
    getRelatedEsgProductsSuccess(state, action) {
      state.relatedEsgProducts = action.payload;
    },

    // GET PRODUCT REVIEWS
    getEsgProductReviewsSuccess(state, action) {
      state.reviews = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
    // is Bom Detail insert open
    bomDetailPopup(state, action) {
      state.isEsgProductBomDetailOpen = action.payload.isEsgProductBomDetailOpen;
    },

    // componenPopup
    componentPopup(state, action) {
      state.isComponentOpen = action.payload.isComponentOpen;
    },

    componentDetailPopup(state, action) {
      state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
    },

    routingPopup(state, action) {
      state.isRoutingOpen = action.payload.isRoutingOpen;
    },

    routingDetailPopup(state, action) {
      state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
    },
    // handler customer form popup
    toggleCustomerPopup(state, action) {
      state.open = action.payload.open;
    },
    // get esgProduct details
    getSingleList(state, action) {
      state.list = action.payload;
    },
    // create EsgProduct
    createEsgProduct(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },

    createEsgProductExcel(state, action) {
      let newEvent = action.payload;
      // newEvent = {
      //   ...newEvent,
      //   id: state.lists.length + 1
      // };
      state.lists = [...state.lists, newEvent];
    },
    // update esgProduct
    updateEsgProduct(state, action) {
      const NewEsgProduct = action.payload;
      const EsgProductUpdate = state.lists.map((item) => {
        if (item.id === NewEsgProduct.id) {
          return NewEsgProduct;
        }
        return item;
      });
      state.lists = EsgProductUpdate;
    },

    updateEsgMonthlyProduction(state, action) {
      const NewEsgProduct = action.payload;
      const EsgProductUpdate = state.lists.map((item) => {
        if (item.id === NewEsgProduct.id) {
          return NewEsgProduct;
        }
        return item;
      });
      state.lists = EsgProductUpdate;
    },

    // delete esgProduct
    deleteEsgProduct(state, action) {
      const esgProductId = action.payload;
      const deleteEsgProduct = state.lists.filter((list) => list.id !== esgProductId);
      state.lists = deleteEsgProduct;
    },

    deleteEsgMonthlyProduction(state, action) {
      const esgProductId = action.payload;
      const deleteEsgMonthlyProduction = state.lists.filter((list) => list.id !== esgProductId);
      state.lists = deleteEsgMonthlyProduction;
    },
    //alert popup
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  reviewEsgProductPopup,
  bomDetailPopup,
  customerPopup,
  componentPopup,
  toggleCustomerPopup,
  componentDetailPopup,
  routingPopup,
  routingDetailPopup,
  toggleBomDetailPopup,
  getLists,
  getMonthlyList,
  alertPopupToggle
} = slice.actions;

// ----------------------------------------------------------------------

export function getEsgProductList() {
  return async () => {
    try {
      const objData = {
        path: '/esgProduct/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
      // console.log('response', response);
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgProductInsert(NewLists) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgProduct/insert`, NewLists, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createEsgProduct(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgProductOnlyInsert(NewLists) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgProduct/OnlyInsert`, NewLists, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createEsgProduct(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgProductUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgProduct/update`, NewLists, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.updateEsgProduct(result));
        return { success: true, data: result };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgProductExcelUpload(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      data.append('excels', JSON.stringify(NewLists.excels));

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgProduct/excelUpload`, data, config);

      if (response.status === 200) {
        const result = response.data;
        dispatch(slice.actions.createEsgProductExcel(result));
        return { success: true, data: result };
      } else {
        alert(response.message);
        return { success: false, message: response.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      return { success: false, error };
    }
  };
}

export function getEsgProductDelete(esgProductId) {
  return async () => {
    try {
      const keyId = {
        id: esgProductId
      };
      const objData = {
        path: '/esgProduct/delete',
        data: keyId
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteEsgProduct(esgProductId));
        return { success: true };
      } else {
        alert(response.data.message);
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterEsgProducts(filter) {
  return async () => {
    try {
      const response = await axios.post('/api/esgProduct/filter', { filter });
      dispatch(slice.actions.filterEsgProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgProduct(id) {
  return async () => {
    try {
      const response = await axios.post('/api/esgProduct/details', { id });
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getRelatedEsgProducts(id) {
  return async () => {
    try {
      const response = await axios.post('/api/esgProduct/related', { id });
      dispatch(slice.actions.getRelatedEsgProductsSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgProductReviews() {
  return async () => {
    try {
      const response = await axios.get('/api/review/list');
      dispatch(slice.actions.getEsgProductReviewsSuccess(response.data.esgProductReviews));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getAddresses() {
  return async () => {
    try {
      const response = await axios.get('/api/address/list');
      dispatch(slice.actions.getAddressesSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/new', address);
      dispatch(slice.actions.addAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address) {
  return async () => {
    try {
      const response = await axios.post('/api/address/edit', address);
      dispatch(slice.actions.editAddressSuccess(response.data.address));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getEsgProductSingleList(esgProductId) {
  return async () => {
    try {
      const response = await axios.post('/api/esgProduct/list/single', esgProductId);
      dispatch(slice.actions.getSingleList(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
