import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null,
  alertPopup: false
};

const slice = createSlice({
  name: 'ghgPurchaseCompanyManager',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    createGhgPurchaseCompanyManager(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateGhgPurchaseCompanyManager(state, action) {
      const updatedManager = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedManager.id ? updatedManager : item));
    },
    deleteGhgPurchaseCompanyManager(state, action) {
      const managerId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== managerId);
      state.lists.totalItems -= 1;
    },
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

export const {
  hasError,
  getLists,
  createGhgPurchaseCompanyManager,
  updateGhgPurchaseCompanyManager,
  deleteGhgPurchaseCompanyManager,
  alertPopupToggle
} = slice.actions;

export default slice.reducer;

// export function getManagersByCompanyId(ghgPurchaseCompanyId) {
//   return async (dispatch) => {
//     try {
//       const objData = {
//         path: `/ghgPurchaseCompanyManager/byCompany`,
//         params: { ghgPurchaseCompanyId }
//       };
//       const response = await ApiService.get(objData);
//       dispatch(getLists(response.data));
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.toString()));
//     }
//   };
// }

export function getGhgPurchaseManagersByCompanyId(ghgPurchaseCompanyId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: `/ghgPurchaseCompanyManager/byCompany?ghgPurchaseCompanyId=${ghgPurchaseCompanyId}`
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGhgPurchaseCompanyManagerList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/ghgPurchaseCompanyManager/list',
        params: { page, pageSize }
      };
      const response = await ApiService.get(objData);
      console.log('response:', response.data);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getGhgPurchaseCompanyManagerInsert(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key] && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (NewLists[key] !== null && NewLists[key] !== undefined) {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/ghgPurchaseCompanyManager/ghgPurchaseCompanyManagerUpload`,
        data,
        config
      );

      if (response.status === 200) {
        dispatch(createGhgPurchaseCompanyManager(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getGhgPurchaseCompanyManagerUpdate(NewLists, id, flag) {
  return async (dispatch) => {
    try {
      let response;
      if (flag === 'Y') {
        const data = new FormData();
        Object.keys(NewLists).forEach((key) => {
          if (key === 'files' && NewLists[key][0]) {
            data.append('file', NewLists[key][0]);
          } else {
            data.append(key, NewLists[key]);
          }
        });
        response = await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/ghgPurchaseCompanyManager/ghgPurchaseCompanyManagerUpdate`,
          data,
          config
        );
      } else {
        const objData = {
          path: '/ghgPurchaseCompanyManager/update',
          data: NewLists
        };
        response = await ApiService.post(objData);
      }

      if (response.status === 200) {
        dispatch(updateGhgPurchaseCompanyManager(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

export function getGhgPurchaseCompanyManagerDelete(managerId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/ghgPurchaseCompanyManager/delete',
        data: { id: managerId }
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteGhgPurchaseCompanyManager(managerId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
