// assets
import {
  AppstoreAddOutlined,
  AreaChartOutlined,
  BuildOutlined,
  ThunderboltOutlined,
  FormOutlined,
  ExperimentOutlined,
  CarOutlined,
  RestOutlined,
  FireOutlined,
  RobotOutlined,
  MenuUnfoldOutlined,
  ShoppingCartOutlined,
  ReconciliationOutlined,
  BulbOutlined,
  ProductOutlined,
  CustomerServiceOutlined,
  BranchesOutlined,
  ClusterOutlined
} from '@ant-design/icons';

// icons
const icons = {
  AppstoreAddOutlined,
  AreaChartOutlined,
  BuildOutlined,
  ThunderboltOutlined,
  FormOutlined,
  ExperimentOutlined,
  CarOutlined,
  RestOutlined,
  FireOutlined,
  RobotOutlined,
  MenuUnfoldOutlined,
  ShoppingCartOutlined,
  ReconciliationOutlined,
  BulbOutlined,
  ProductOutlined,
  CustomerServiceOutlined,
  BranchesOutlined,
  ClusterOutlined
};
// ==============================|| MENU ITEMS - mes ||============================== //

const esg = {
  id: 'esg',
  title: 'ESG',
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    {
      id: 'esg-esgMonthlyCarbonEmissions',
      title: '에너지 사용량',
      type: 'item',
      url: '/esg/esgMonthlyCarbonEmissions/list',
      icon: icons.BulbOutlined,
      breadcrumbs: false
    },
    {
      id: 'esg-esgProductCarbon',
      title: '생산제품 탄소배출',
      type: 'item',
      url: '/esg/esgProductCarbon/list',
      icon: icons.ExperimentOutlined,
      breadcrumbs: false
    },
    {
      id: 'esg-esgProductSupplyChainCarbon',
      title: '공급망 탄소배출',
      type: 'item',
      url: '/esgSupplyChain/esgProductSupplyChainCarbon/list',
      icon: icons.ClusterOutlined,
      breadcrumbs: false
    },
    {
      id: 'GHG-ghgPurchase',
      title: '구매 탄소배출',
      type: 'item',
      url: '/ghg/ghgPurchase/list',
      icon: icons.ShoppingCartOutlined,
      breadcrumbs: false
    }
    // {
    //   id: 'esg-esgProduct',
    //   title: '생산 제픔관리',
    //   type: 'item',
    //   url: '/esg/esgProduct/list',
    //   icon: icons.ProductOutlined,
    //   breadcrumbs: false
    // }
    // {
    //   id: 'esg-esgCustomer',
    //   title: '고객사 관리',
    //   type: 'item',
    //   url: '/esg/esgCustomer/list',
    //   icon: icons.CustomerServiceOutlined,
    //   breadcrumbs: false
    // }
  ]
};

export default esg;
