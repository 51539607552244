// assets
import {
  BuildOutlined,
  MenuUnfoldOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  FileTextOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  DesktopOutlined,
  PoweroffOutlined,
  FireOutlined,
  CarOutlined,
  RestOutlined,
  ThunderboltOutlined,
  ExperimentOutlined,
  RobotOutlined,
  AreaChartOutlined,
  BulbOutlined,
  FormOutlined,
  ProductOutlined,
  ClusterOutlined
} from '@ant-design/icons';

// icons
const icons = {
  BuildOutlined,
  MenuUnfoldOutlined,
  CalendarOutlined,
  CustomerServiceOutlined,
  MessageOutlined,
  ShoppingCartOutlined,
  UserOutlined,
  AppstoreAddOutlined,
  FileTextOutlined,
  DesktopOutlined,
  PoweroffOutlined,
  FireOutlined,
  CarOutlined,
  RestOutlined,
  ThunderboltOutlined,
  ExperimentOutlined,
  RobotOutlined,
  AreaChartOutlined,
  BulbOutlined,
  FormOutlined,
  ProductOutlined,
  ClusterOutlined
};

// Menu Items - aixai

const BaselineInformation = {
  id: 'baselineInformation',
  title: '기준정보',
  icon: icons.AppstoreAddOutlined,
  type: 'group',
  children: [
    {
      id: 'esg-esgProduct',
      title: '생산제픔 관리',
      type: 'item',
      url: '/esg/esgProduct/list',
      icon: icons.ProductOutlined,
      breadcrumbs: false
    },
    {
      id: 'esg-esgCustomer',
      title: '고객사 관리',
      type: 'item',
      url: '/esg/esgCustomer/list',
      icon: icons.CustomerServiceOutlined,
      breadcrumbs: false
    },
    {
      id: 'esg-esgProductSupplyChain',
      title: '납품 제품 공급망 관리',
      type: 'item',
      url: '/esgSupplyChain/esgProductSupplyChain/list',
      icon: icons.ClusterOutlined,
      breadcrumbs: false
    },
    {
      id: 'esg-esgSupplyChain',
      title: '제품 공급망 관리',
      type: 'item',
      url: '/esgSupplyChain/esgSupplyChain/list',
      icon: icons.ClusterOutlined,
      breadcrumbs: false
    },
    {
      id: 'GHG-ghgPurchaseCompany',
      title: '구매 공급망 관리',
      type: 'item',
      url: '/ghg/ghgPurchaseCompany/list',
      icon: icons.UserOutlined,
      breadcrumbs: false
    }
  ]
};

export default BaselineInformation;
