// esg
import esg from './esg';
// import esgSupplyChain from './esgSupplyChain';

// GHG
// import ghg from './ghg';
import baselineInformation from './baselineInformation';

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: [esg, baselineInformation]
};

export default menuItems;
