import { createSlice } from '@reduxjs/toolkit';
import ApiService from 'services/ApiService';
import axios from 'axios';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data'
  }
};
if (token) {
  config.headers['x-access-token'] = token;
}

const initialState = {
  lists: {
    items: [],
    totalItems: 0
  },
  currentPage: 0,
  pageSize: 150,
  error: null,
  list: null,
  alertPopup: false
};

const slice = createSlice({
  name: 'ghgPurchaseCompany',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    getLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    getSelectLists(state, action) {
      state.lists = action.payload;
      state.currentPage = action.payload.currentPage;
      state.pageSize = action.payload.pageSize;
    },
    createGhgPurchaseCompany(state, action) {
      state.lists.items = [...state.lists.items, action.payload];
      state.lists.totalItems += 1;
    },
    updateGhgPurchaseCompany(state, action) {
      const updatedCompany = action.payload;
      state.lists.items = state.lists.items.map((item) => (item.id === updatedCompany.id ? updatedCompany : item));
    },
    deleteGhgPurchaseCompany(state, action) {
      const companyId = action.payload;
      state.lists.items = state.lists.items.filter((item) => item.id !== companyId);
      state.lists.totalItems -= 1;
    },
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

export const {
  hasError,
  getLists,
  getSelectLists,
  createGhgPurchaseCompany,
  updateGhgPurchaseCompany,
  deleteGhgPurchaseCompany,
  alertPopupToggle
} = slice.actions;

export default slice.reducer;

export function getGhgPurchaseCompanyList(page, pageSize) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/ghgPurchaseCompany/list',
        params: { page, pageSize }
      };

      const response = await ApiService.get(objData);
      console.log('response:', response.data);
      dispatch(
        getLists({
          items: response.data.items,
          totalItems: response.data.totalItems,
          currentPage: page - 1,
          pageSize: pageSize
        })
      );
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getGhgPurchaseCompanySelectList() {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/ghgPurchaseCompany/selectList'
      };

      const response = await ApiService.get(objData);
      // console.log('response:', response);
      dispatch(getSelectLists(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
    }
  };
}

export function getGhgPurchaseCompanyInsert(NewLists) {
  console.log('NewLists:', NewLists);
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.entries(NewLists).forEach(([key, value]) => {
        if (key === 'files' && value && value[0]) {
          data.append('file', value[0]);
        } else if (key === 'ghgPurchaseCompanyManager') {
          // ghgPurchaseCompanyManager 배열을 JSON 문자열로 변환하여 추가
          data.append('ghgPurchaseCompanyManager', JSON.stringify(NewLists[key]));
        } else if (value !== null && value !== undefined) {
          data.append(key, value);
        }
      });

      console.log('Sending data:', Object.fromEntries(data));

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ghgPurchaseCompany/ghgPurchaseCompanyUpload`, data, config);

      console.log('response:', response);

      if (response.status === 200 && response.data) {
        dispatch(createGhgPurchaseCompany(response.data));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data?.message || 'Unknown error occurred');
      }
    } catch (error) {
      console.error('API Error:', error);
      dispatch(hasError(error.message || error.toString()));
      return {
        success: false,
        error: error.response?.data?.message || error.message || 'An unexpected error occurred'
      };
    }
  };
}

export function getGhgPurchaseCompanyUpdate(NewLists) {
  return async (dispatch) => {
    try {
      const data = new FormData();
      Object.keys(NewLists).forEach((key) => {
        if (key === 'files' && NewLists[key][0]) {
          data.append('file', NewLists[key][0]);
        } else if (key === 'ghgPurchaseCompanyManager') {
          // ghgPurchaseCompanyManager 배열을 JSON 문자열로 변환하여 추가
          data.append('ghgPurchaseCompanyManager', JSON.stringify(NewLists[key]));
        } else {
          data.append(key, NewLists[key]);
        }
      });

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ghgPurchaseCompany/ghgPurchaseCompanyUpdate`, data, config);

      console.log('response:', response);

      if (response.status === 200) {
        dispatch(updateGhgPurchaseCompany(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      return { success: false, error };
    }
  };
}

// export function getGhgPurchaseCompanyUpdate(NewLists, id, flag) {
//   return async (dispatch) => {
//     try {
//       let response;
//       if (flag === 'Y') {
//         const data = new FormData();
//         Object.keys(NewLists).forEach((key) => {
//           if (key === 'files' && NewLists[key][0]) {
//             data.append('file', NewLists[key][0]);
//           } else if (key === 'ghgPurchaseCompanyManager') {
//             // ghgPurchaseCompanyManager 배열을 JSON 문자열로 변환하여 추가
//             data.append('ghgPurchaseCompanyManager', JSON.stringify(NewLists[key]));
//           } else {
//             data.append(key, NewLists[key]);
//           }
//         });
//         response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/ghgPurchaseCompany/ghgPurchaseCompanyUpdate`, data, config);
//       } else {
//         const objData = {
//           path: '/ghgPurchaseCompany/update',
//           data: NewLists
//         };
//         response = await ApiService.post(objData);
//       }

//       if (response.status === 200) {
//         dispatch(updateGhgPurchaseCompany(response.data));
//         return { success: true, data: response.data };
//       } else {
//         return { success: false, message: response.data.message };
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error.toString()));
//       return { success: false, error };
//     }
//   };
// }

export function getGhgPurchaseCompanyDelete(companyId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/ghgPurchaseCompany/delete',
        data: { id: companyId }
      };

      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(deleteGhgPurchaseCompany(companyId));
        return { success: true, data: response.data };
      } else {
        throw new Error(response.data.message);
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error.toString()));
      throw error;
    }
  };
}
