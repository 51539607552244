import { lazy } from 'react';

// project import
import MainLayout from 'layout/MainLayout';
import Loadable from 'components/Loadable';
import AuthGuard from 'utils/route-guard/AuthGuard';

// render - ghg
const GhgPurchaseList = Loadable(lazy(() => import('pages/ghg/purchase/list')));
const GhgPurchaseCreate = Loadable(lazy(() => import('pages/ghg/purchase/create')));
const GhgPurchaseEdit = Loadable(lazy(() => import('pages/ghg/purchase/edit')));

const GhgPurchaseCompanyList = Loadable(lazy(() => import('pages/ghg/purchaseCompany/list')));
const GhgPurchaseCompanyCreate = Loadable(lazy(() => import('pages/ghg/purchaseCompany/create')));
const GhgPurchaseCompanyEdit = Loadable(lazy(() => import('pages/ghg/purchaseCompany/edit')));

const GhgPurchaseCompanyManagerList = Loadable(lazy(() => import('pages/ghg/purchaseCompanyManager/list')));
const GhgPurchaseCompanyManagerCreate = Loadable(lazy(() => import('pages/ghg/purchaseCompanyManager/create')));
const GhgPurchaseCompanyManagerEdit = Loadable(lazy(() => import('pages/ghg/purchaseCompanyManager/edit')));

// ==============================|| ghg ROUTING ||============================== //

const ghgRoutes = {
  path: '/',
  children: [
    {
      path: '/',
      element: (
        <AuthGuard>
          <MainLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: 'ghg',
          children: [
            {
              path: 'ghgPurchase',
              children: [
                {
                  path: 'list',
                  element: <GhgPurchaseList />
                },
                {
                  path: 'create',
                  element: <GhgPurchaseCreate />
                },
                {
                  path: 'edit/:id',
                  element: <GhgPurchaseEdit />
                }
              ]
            },
            {
              path: 'ghgPurchaseCompany',
              children: [
                {
                  path: 'list',
                  element: <GhgPurchaseCompanyList />
                },
                {
                  path: 'create',
                  element: <GhgPurchaseCompanyCreate />
                },
                {
                  path: 'edit/:id',
                  element: <GhgPurchaseCompanyEdit />
                }
              ]
            },
            {
              path: 'ghgPurchaseCompanyManager',
              children: [
                {
                  path: 'list',
                  element: <GhgPurchaseCompanyManagerList />
                },
                {
                  path: 'create',
                  element: <GhgPurchaseCompanyManagerCreate />
                },
                {
                  path: 'edit/:id',
                  element: <GhgPurchaseCompanyManagerEdit />
                }
              ]
            }
          ]
        }
      ]
    }
  ]
};

export default ghgRoutes;
