// // project imports
// import axios from 'axios';
// import ApiService from 'services/ApiService';
// import { dispatch } from '../index';

// // third-party
// import { createSlice } from '@reduxjs/toolkit';

// // ----------------------------------------------------------------------

// const token = window.localStorage.getItem('serviceToken');
// const config = {
//   headers: {
//     'content-type': 'multipart/form-data'
//   }
// };
// if (token) {
//   config.headers['x-access-token'] = token;
// }

// const initialState = {
//   isOpen: false,
//   list: null,
//   lists: [],
//   error: null,
//   esgSupplyChains: [],
//   esgSupplyChain: null,
//   relatedEsgSupplyChains: [],
//   reviews: [],
//   addresses: [],
//   isEsgSupplyChainBomDetailOpen: false,
//   isComponentOpen: false,
//   isComponentDetailOpen: false,
//   isRoutingOpen: false,
//   isSupplyChainOpen: false,
//   isRoutingDetailOpen: false,
//   alertPopup: false
// };

// const slice = createSlice({
//   name: 'esgSupplyChain',
//   initialState,
//   reducers: {
//     // review esgSupplyChain popup
//     reviewEsgSupplyChainPopup(state, action) {
//       state.isOpen = action.payload.isOpen;
//     },

//     // HAS ERROR
//     hasError(state, action) {
//       state.error = action.payload;
//     },

//     // is customer open
//     customerPopup(state, action) {
//       state.isSupplyChainOpen = action.payload.isSupplyChainOpen;
//     },

//     // GET PRODUCTS
//     // getEsgSupplyChainsSuccess(state, action) {
//     //   state.esgSupplyChain = action.payload;
//     // },

//     // FILTER PRODUCTS
//     filterEsgSupplyChainsSuccess(state, action) {
//       state.esgSupplyChain = action.payload;
//     },

//     // GET PRODUCT
//     getLists(state, action) {
//       state.lists = action.payload;
//     },

//     // GET RELATED PRODUCTS
//     getRelatedEsgSupplyChainsSuccess(state, action) {
//       state.relatedEsgSupplyChains = action.payload;
//     },

//     // GET PRODUCT REVIEWS
//     getEsgSupplyChainReviewsSuccess(state, action) {
//       state.reviews = action.payload;
//     },

//     // GET ADDRESSES
//     getAddressesSuccess(state, action) {
//       state.addresses = action.payload;
//     },

//     // ADD ADDRESS
//     addAddressSuccess(state, action) {
//       state.addresses = action.payload;
//     },

//     // EDIT ADDRESS
//     editAddressSuccess(state, action) {
//       state.addresses = action.payload;
//     },
//     // is Bom Detail insert open
//     bomDetailPopup(state, action) {
//       state.isEsgSupplyChainBomDetailOpen = action.payload.isEsgSupplyChainBomDetailOpen;
//     },

//     // componenPopup
//     componentPopup(state, action) {
//       state.isComponentOpen = action.payload.isComponentOpen;
//     },

//     componentDetailPopup(state, action) {
//       state.isComponentDetailOpen = action.payload.isComponentDetailOpen;
//     },

//     routingPopup(state, action) {
//       state.isRoutingOpen = action.payload.isRoutingOpen;
//     },

//     routingDetailPopup(state, action) {
//       state.isRoutingDetailOpen = action.payload.isRoutingDetailOpen;
//     },
//     // handler customer form popup
//     toggleSupplyChainPopup(state, action) {
//       state.open = action.payload.open;
//     },
//     // get esgSupplyChain details
//     getSingleList(state, action) {
//       state.list = action.payload;
//     },
//     // create EsgSupplyChain
//     createEsgSupplyChain(state, action) {
//       let newEvent = action.payload;
//       state.lists = [...state.lists, newEvent];
//     },

//     sendMailEsgSupplyChainCarbon(state, action) {
//       let newEvent = action.payload;
//       state.lists = [...state.lists, newEvent];
//     },
//     // update esgSupplyChain
//     updateEsgSupplyChain(state, action) {
//       const NewEsgSupplyChain = action.payload;
//       const EsgSupplyChainUpdate = state.lists.map((item) => {
//         if (item.id === NewEsgSupplyChain.id) {
//           return NewEsgSupplyChain;
//         }
//         return item;
//       });
//       state.lists = EsgSupplyChainUpdate;
//     },

//     // delete esgSupplyChain
//     deleteEsgSupplyChain(state, action) {
//       const esgSupplyChainId = action.payload;
//       const deleteEsgSupplyChain = state.lists.filter((list) => list.id !== esgSupplyChainId);
//       state.lists = deleteEsgSupplyChain;
//     },
//     //alert popup
//     alertPopupToggle(state, action) {
//       state.alertPopup = action.payload.alertToggle;
//     }
//   }
// });

// // Reducer
// export default slice.reducer;

// export const {
//   reviewEsgSupplyChainPopup,
//   bomDetailPopup,
//   customerPopup,
//   componentPopup,
//   toggleSupplyChainPopup,
//   componentDetailPopup,
//   routingPopup,
//   routingDetailPopup,
//   toggleBomDetailPopup,
//   getLists,
//   alertPopupToggle,
//   sendMailEsgSupplyChainCarbon
// } = slice.actions;

// // ----------------------------------------------------------------------

// export function getEsgSupplyChainList() {
//   return async () => {
//     try {
//       const objData = {
//         path: '/esgSupplyChain/list'
//       };
//       const response = await ApiService.get(objData);
//       dispatch(slice.actions.getLists(response.data));
//       // console.log('getEsgSupplyChainList', response.data);
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//       return { success: false, error };
//     }
//   };
// }

// export function getEsgSupplyChainInsert(NewLists) {
//   // console.log('NewLists', NewLists);
//   return async (dispatch) => {
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgSupplyChain/insert`, NewLists, config);

//       if (response.status === 200) {
//         const result = response.data;
//         dispatch(slice.actions.createEsgSupplyChain(result));
//         return { success: true, data: result };
//       } else {
//         alert(response.message);
//         return { success: false, message: response.message };
//       }
//     } catch (error) {
//       console.error('Update error:', error);
//       dispatch(slice.actions.hasError(error));
//       return { success: false, error };
//     }
//   };
// }

// export function getEsgSupplyChainUpdate(NewLists) {
//   // console.log('NewLists', NewLists);
//   return async (dispatch) => {
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgSupplyChain/update`, NewLists, config);

//       if (response.status === 200) {
//         const result = response.data;
//         dispatch(slice.actions.updateEsgSupplyChain(result));
//         return { success: true, data: result };
//       } else {
//         alert(response.data.message);
//         return { success: false, message: response.data.message };
//       }
//     } catch (error) {
//       console.error('Update error:', error);
//       dispatch(slice.actions.hasError(error));
//       return { success: false, error };
//     }
//   };
// }

// export function getEsgSupplyChainCarbonSendMail(NewLists) {
//   console.log('NewLists', NewLists);
//   return async (dispatch) => {
//     try {
//       const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgSupplyChainCarbon/sendMail`, NewLists, config);
//       console.log('response', response);
//       if (response.status === 200) {
//         const result = response.data;
//         dispatch(slice.actions.sendMailEsgSupplyChainCarbon(result));
//         return { success: true, data: result };
//       } else {
//         alert(response.message);
//         return { success: false, message: response.message };
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//       return { success: false, error };
//     }
//   };
// }

// export function getEsgSupplyChainDelete(esgSupplyChainId) {
//   return async () => {
//     try {
//       const keyId = {
//         id: esgSupplyChainId
//       };
//       const objData = {
//         path: '/esgSupplyChain/delete',
//         data: keyId
//       };

//       // console.log('objData', objData);

//       const response = await ApiService.post(objData);
//       if (response.status === 200) {
//         dispatch(slice.actions.deleteEsgSupplyChain(esgSupplyChainId));
//         return { success: true, data: response.data };
//       } else {
//         alert(response.data.message);
//         return { success: false, message: response.message };
//       }
//     } catch (error) {
//       dispatch(slice.actions.hasError(error));
//     }
//   };
// }

import axios from 'axios';
import ApiService from 'services/ApiService';
import { dispatch } from '../index';
import { createSlice } from '@reduxjs/toolkit';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data',
    'x-access-token': token
  }
};

const initialState = {
  isOpen: false,
  list: null,
  lists: [],
  isEsgSupplyChainBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isSupplyChainOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'esgSupplyChain',
  initialState,
  reducers: {
    getLists(state, action) {
      state.lists = action.payload;
    },
    toggleSupplyChainPopup(state, action) {
      state.open = action.payload.open;
    },
    getSingleList(state, action) {
      state.list = action.payload;
    },
    createEsgSupplyChain(state, action) {
      state.lists = [...state.lists, action.payload];
    },
    sendMailEsgSupplyChainCarbon(state, action) {
      state.lists = [...state.lists, action.payload];
    },
    updateEsgSupplyChain(state, action) {
      const updatedEsgSupplyChain = action.payload;
      state.lists = state.lists.map((item) => (item.id === updatedEsgSupplyChain.id ? updatedEsgSupplyChain : item));
    },
    deleteEsgSupplyChain(state, action) {
      state.lists = state.lists.filter((list) => list.id !== action.payload);
    }
  }
});

export default slice.reducer;

export const { toggleSupplyChainPopup, getLists, sendMailEsgSupplyChainCarbon } = slice.actions;

export function getEsgSupplyChainList() {
  return async () => {
    try {
      const objData = {
        path: '/esgSupplyChain/list'
      };
      const response = await ApiService.get(objData);
      dispatch(slice.actions.getLists(response.data));
    } catch (error) {
      console.error('Error fetching EsgSupplyChain list:', error);
      return { success: false, error };
    }
  };
}

export function getEsgSupplyChainInsert(newLists) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgSupplyChain/insert`, newLists, config);
      if (response.status === 200) {
        dispatch(slice.actions.createEsgSupplyChain(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Insert error:', error);
      return { success: false, error };
    }
  };
}

export function getEsgSupplyChainUpdate(updatedLists) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgSupplyChain/update`, updatedLists, config);
      if (response.status === 200) {
        console.log('response', response);
        dispatch(slice.actions.updateEsgSupplyChain(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Update error:', error);
      return { success: false, error };
    }
  };
}

export function getEsgSupplyChainCarbonSendMail(newLists) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgSupplyChainCarbon/sendMail`, newLists, config);
      if (response.status === 200) {
        dispatch(slice.actions.sendMailEsgSupplyChainCarbon(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Send mail error:', error);
      return { success: false, error };
    }
  };
}

export function getEsgSupplyChainDelete(esgSupplyChainId) {
  return async (dispatch) => {
    try {
      const objData = {
        path: '/esgSupplyChain/delete',
        data: { id: esgSupplyChainId }
      };
      const response = await ApiService.post(objData);
      if (response.status === 200) {
        dispatch(slice.actions.deleteEsgSupplyChain(esgSupplyChainId));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Delete error:', error);
      return { success: false, error };
    }
  };
}
