import axios from 'axios';
// import ApiService from 'services/ApiService';
// import { dispatch } from '../index';
import { createSlice } from '@reduxjs/toolkit';

const token = window.localStorage.getItem('serviceToken');
const config = {
  headers: {
    'content-type': 'multipart/form-data',
    'x-access-token': token
  }
};

const initialState = {
  list: null,
  lists: [],
  isEsgSupplyChainBomDetailOpen: false,
  isComponentOpen: false,
  isComponentDetailOpen: false,
  isRoutingOpen: false,
  isSupplyChainOpen: false,
  isRoutingDetailOpen: false,
  alertPopup: false
};

const slice = createSlice({
  name: 'esgSupplyChainCarbon',
  initialState,
  reducers: {
    hasError(state, action) {
      state.error = action.payload;
    },
    createEsgSupplyChainCarbon(state, action) {
      state.lists = [...state.lists, action.payload];
    },
    alertPopupToggle(state, action) {
      state.alertPopup = action.payload.alertToggle;
    }
  }
});

export default slice.reducer;

export const { createEsgSupplyChainCarbon, alertPopupToggle } = slice.actions;

export function getEsgSupplyChainCarbonSendMail(NewLists) {
  console.log('NewLists:', NewLists);
  return async (dispatch) => {
    try {
      const data = new FormData();

      // NewLists의 모든 속성을 순회하며 FormData에 추가
      Object.keys(NewLists).forEach((key) => {
        if (NewLists[key] != null) {
          data.append(key, NewLists[key]);
        }
      });

      console.log('FormData entries:');
      for (let pair of data.entries()) {
        console.log(pair[0] + ': ' + pair[1]);
      }

      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/esgSupplyChainCarbon/sendMail`, data, config);
      console.log('response:', response);
      if (response.status === 200) {
        dispatch(slice.actions.createEsgSupplyChainCarbon(response.data));
        return { success: true, data: response.data };
      } else {
        return { success: false, message: response.data.message };
      }
    } catch (error) {
      console.error('Error sending EsgSupplyChainCarbon mail:', error);
      return { success: false, error: error.message };
    }
  };
}
