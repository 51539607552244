import { lazy } from 'react';
import { useRoutes } from 'react-router-dom';

// project import

import Loadable from 'components/Loadable';
import MainRoutes from './MainRoutes';
import CommonLayout from 'layout/CommonLayout';
import LoginRoutes from './LoginRoutes';
import Notification from './NotificationRoutes';
import ProfileRoutes from './ProfileRoutes';
import Bbs from './BbsRoutes';
import EsgRoutes from './EsgRoutes';
import EsgSupplyRoutes from './EsgSupplyRoutes';
import GhgRouters from './GhgRoutes';

// render - landing page
const PagesLanding = Loadable(lazy(() => import('pages/landing')));
const NewsLanding = Loadable(lazy(() => import('pages/bbs/news/newsLanding')));

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
  return useRoutes([
    {
      path: '/',
      element: <CommonLayout layout="landing" />,
      children: [
        {
          path: '/',
          element: <PagesLanding />
        },
        {
          path: '/news',
          element: <NewsLanding />
        }
      ]
    },
    MainRoutes,
    LoginRoutes,
    EsgRoutes,
    EsgSupplyRoutes,
    GhgRouters,
    Notification,
    ProfileRoutes,
    Bbs
  ]);
}
